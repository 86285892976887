import React, { Component } from "react";
import { connect } from "react-redux";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import QRCode from "react-qr-code";
import Slider from "react-slick";
import { Icon } from "@iconify/react";
import { isDesktop } from "react-device-detect";
import MobileDownloadApp from "./MobileDownloadApp";
import { download } from "../../store/ducks/downloadApp/actions";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import { updateViewCount } from "../../store/ducks/referral/actions";
import { getUserData } from "../../store/ducks/currentUser/actions";

export class DownloadApp extends Component {
  constructor(props) {
    super(props);
    // const t = this.props.t;
    this.state = {
      downloadDevice: "android",
      activeSlide: 0,
      downloadUrl: "",
      iosDownloadUrl: "",

      loading: true
    };
  }

  componentDidMount() {
    let params = {};
    // params = { 'country_id': this.props.currentUser.country_id }
    this.props.download(params);
    
    if(sessionStorage.getItem('updateView') && sessionStorage.getItem('referralCode')){
      let viewData = {};
      viewData.referralCode = sessionStorage.getItem('referralCode');
      this.props.updateViewCount(viewData);
      sessionStorage.removeItem('updateView'); //remove immediately after viewcount call api
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // const { t } = this.props;

    if (
      nextProps.rs !== this.props.rs &&
      nextProps.rs !== undefined &&
      nextProps.rs.status === 200
    ) {
      if (!!nextProps.rs.data.data.downloadUrl) {
        console.log(nextProps.rs.data.data.downloadUrl)
        this.setState({
          downloadUrl: nextProps.rs.data.data.downloadUrl,
          loading: false,
        });
      }
      else{
        this.setState({
          loading: false
        });
      }
    }

    return true;
  }

  newDownloadDevice = (os) => {
    this.setState({
      downloadDevice: os,
      activeSlide: 0,
    });
    // this.slider.slickGoTo(0); 
  };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const appImg = this.imageImport(
      require.context(
        "../../assets/images/download-app",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const androidGuideImg = [
      "android-s1",
      "android-s2",
      "android-s3",
      "android-s4",
      "android-s5",
    ];
    const iosGuideImg = [
      "ios-s1",
      "ios-s2",
      "ios-s3",
      // "ios-s4",
    ];

    const playStoreImg = [
      "playstore-s1",
      "playstore-s2",
      "playstore-s3",
      "playstore-s4",
    ]

    const settings = {
      dots: true,
      infinite: true,
      fade: true,
      cssEase: "cubic-bezier(0.7, 0, 0.3, 1)",
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      beforeChange: (current, next) =>
        this.setState({ oldSlide: current, activeSlide: next }),
    };

    // let merchantTitle = process.env.REACT_APP_WEBSITE_TITLE;
    return (
      <Auxiliary>
        {isDesktop ? (
          <>
            {this.state.loading && <LoadingPage />}
            <section className="app-section">
              <div className="global-section">
                <div className="x-container p-0">
                  <div className="position-relative py-5">
                    <div className="section-content-container card-shadow">
                      <div className="section-content">
                        <h2>{t("download-app.title")}</h2>
                        <p>{t("download-app.title-desc")}</p>

                        <div className="qr-code-container">
                          <div className="row justify-content-center">
                            <div className="col-4">
                              <div className="qr-code">
                                <QRCode
                                  value={`https://apps.apple.com/us/app/chatcortex/id6738694629`}
                                  id="qr-code"
                                  size={150}
                                />
                              </div>
                              <div className="d-flex mb-5">
                                <a
                                  className={`btn mt-3 btn-white`}
                                  target="_blank"
                                  href={`https://apps.apple.com/us/app/chatcortex/id6738694629`}
                                >
                                  <img
                                    src={
                                      appImg[
                                      `apple-icon.webp`
                                      ]
                                    }
                                    alt="apple"
                                  />
                                    <div>
                                      <small>{t("download-app.available-on")}</small>
                                      <span>{t("download-app.apple-ios")}</span>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            {/* <div className="col-4">
                              <div className="qr-code">
                                <QRCode
                                  value={this.state.downloadUrl}
                                  id="qr-code"
                                  size={150}
                                />
                              </div>

                              <div className="qr-type">
                                <img
                                  src={appImg["android-icon.webp"]}
                                  alt="apple"
                                />
                                <p>{t("download-app.scan-android")}</p>
                              </div>
                            </div> */}
                            <div className="col-4">
                              <div className="qr-code">
                                <QRCode
                                  value={"https://play.google.com/store/apps/details?id=com.thinkbit.futureflow"}
                                  // value={process.env.REACT_APP_MERCHANT_ANDROID_APP_URL}
                                  id="qr-code"
                                  size={150}
                                />
                              </div>
                              <div className="d-flex mb-5">
                                <a
                                  className={`btn mt-3 btn-white`}
                                  target="_blank"
                                  href={"https://play.google.com/store/apps/details?id=com.thinkbit.futureflow"}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><path fill="#abb8d6" d="M48 59.49v393a4.33 4.33 0 0 0 7.37 3.07L260 256L55.37 56.42A4.33 4.33 0 0 0 48 59.49ZM345.8 174L89.22 32.64l-.16-.09c-4.42-2.4-8.62 3.58-5 7.06l201.13 192.32ZM84.08 472.39c-3.64 3.48.56 9.46 5 7.06l.16-.09L345.8 338l-60.61-57.95ZM449.38 231l-71.65-39.46L310.36 256l67.37 64.43L449.38 281c19.49-10.77 19.49-39.23 0-50Z" /></svg>
                                    <div>
                                      <small>{t("download-app.available-on")}</small>
                                      <span>{t("download-app.play-store")}</span>
                                    </div>
                                  </a>
                                </div> 
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="section-image-container">
                      <LazyLoadImage 
                        className="app-img"
                        src={appImg["download-app-img.webp"]}
                        visibleByDefault={true}
                        alt="download app"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="x-container mt-0">
                <div className="guide-container card-shadow">
                  <div className="slick-container">
                    <Slider ref={(c) => (this.slider = c)} {...settings}>
                      {iosGuideImg &&
                        iosGuideImg.map((item, index) => (
                          <img
                            key={index}
                            className="guide-image"
                            src={appImg[`${item}.webp`]}
                            alt={item}
                          />
                        ))
                      }
                    </Slider>
                    <div className="slick-arrow">
                      <div
                        className="slick-arrow-btn prev"
                        onClick={() => this.slider.slickPrev()}
                      >
                        <Icon icon="uil:angle-left-b" />
                      </div>
                      <div
                        className="slick-arrow-btn next"
                        onClick={() => this.slider.slickNext()}
                      >
                        <Icon icon="uil:angle-right-b" />
                      </div>
                    </div>
                  </div>
                  <div className="guide-content">
                    <h2>{t("download-app.download-guide")}</h2>
                    <div className="d-flex mb-5">
                      <button
                        className={`btn ${this.state.downloadDevice === "ios"
                          ? "enter-btn-blue"
                          : "btn-white"
                          }`}
                        onClick={() => this.newDownloadDevice("ios")}
                      >
                        <img
                          src={
                            appImg[
                            `apple-icon${this.state.downloadDevice === "ios"
                              ? "-white"
                              : ""
                            }.webp`
                            ]
                          }
                          alt="apple"
                        />
                        {t("download-app.apple-ios")}
                      </button>
                    </div>
                    {iosGuideImg &&
                      iosGuideImg.map((item, index) => {
                        if (this.state.activeSlide + 1 === index + 1) {
                          return (
                            <div key={index}>
                              <h4 className="mb-3">
                                {t("download-app.ios-guide")}
                              </h4>
                              <p className="guide-step">
                                {t(`download-app.step-${index + 1}`)}
                              </p>
                              <p className="guide-desc">
                                {t(`download-app.ios-step-${index + 1}`, { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                              </p>
                            </div>
                          );
                        }
                        else{
                          return null;
                        }
                      })                
                    }
                  </div>
                </div>
              </div> */}


              {/* <div className="x-container mt-0">
                <div className="guide-container card-shadow">
                  <div className="slick-container">
                    <Slider ref={(c) => (this.slider = c)} {...settings}>
                      {this.state.downloadDevice === "android"
                        ? androidGuideImg &&
                        androidGuideImg.map((item, index) => (
                          <img
                            key={index}
                            className="guide-image"
                            src={appImg[`${item}.webp`]}
                            alt={item}
                          />
                        ))
                        : this.state.downloadDevice === "ios" ? iosGuideImg &&
                          iosGuideImg.map((item, index) => (
                            <img
                              key={index}
                              className="guide-image"
                              src={appImg[`${item}.webp`]}
                              alt={item}
                            />
                          ))
                          : this.state.downloadDevice === "playStore" && playStoreImg &&
                          playStoreImg.map((item, index) => (
                            <img
                              key={index}
                              className="guide-image"
                              src={appImg[`${item}.webp`]}
                              alt={item}
                            />
                          ))
                      }
                    </Slider>
                    <div className="slick-arrow">
                      <div
                        className="slick-arrow-btn prev"
                        onClick={() => this.slider.slickPrev()}
                      >
                        <Icon icon="uil:angle-left-b" />
                      </div>
                      <div
                        className="slick-arrow-btn next"
                        onClick={() => this.slider.slickNext()}
                      >
                        <Icon icon="uil:angle-right-b" />
                      </div>
                    </div>
                  </div>
                  <div className="guide-content">
                    <h2>{t("download-app.download-guide")}</h2>
                    <div className="d-flex mb-5">
                      <button
                        className={`btn ${this.state.downloadDevice === "ios"
                          ? "enter-btn-blue"
                          : "btn-white"
                          }`}
                        onClick={() => this.newDownloadDevice("ios")}
                      >
                        <img
                          src={
                            appImg[
                            `apple-icon${this.state.downloadDevice === "ios"
                              ? "-white"
                              : ""
                            }.webp`
                            ]
                          }
                          alt="apple"
                        />
                        {t("download-app.apple-ios")}
                      </button>
                      <button
                        className={`btn ${this.state.downloadDevice === "android"
                          ? "enter-btn-blue"
                          : "btn-white"
                          }`}
                        onClick={() => this.newDownloadDevice("android")}
                      >
                        <img
                          src={
                            appImg[
                            `android-icon${this.state.downloadDevice === "android"
                              ? "-white"
                              : ""
                            }.webp`
                            ]
                          }
                          alt="android"
                        />
                        {t("download-app.android")}
                      </button>
                      <button
                        className={`btn ${this.state.downloadDevice === "playStore"
                          ? "enter-btn-blue"
                          : "btn-white"
                          }`}
                        onClick={() => this.newDownloadDevice("playStore")}
                      >
                        {
                          this.state.downloadDevice === "playStore" ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><path fill="#ffffff" d="M48 59.49v393a4.33 4.33 0 0 0 7.37 3.07L260 256L55.37 56.42A4.33 4.33 0 0 0 48 59.49ZM345.8 174L89.22 32.64l-.16-.09c-4.42-2.4-8.62 3.58-5 7.06l201.13 192.32ZM84.08 472.39c-3.64 3.48.56 9.46 5 7.06l.16-.09L345.8 338l-60.61-57.95ZM449.38 231l-71.65-39.46L310.36 256l67.37 64.43L449.38 281c19.49-10.77 19.49-39.23 0-50Z" /></svg>
                          ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><path fill="#abb8d6" d="M48 59.49v393a4.33 4.33 0 0 0 7.37 3.07L260 256L55.37 56.42A4.33 4.33 0 0 0 48 59.49ZM345.8 174L89.22 32.64l-.16-.09c-4.42-2.4-8.62 3.58-5 7.06l201.13 192.32ZM84.08 472.39c-3.64 3.48.56 9.46 5 7.06l.16-.09L345.8 338l-60.61-57.95ZM449.38 231l-71.65-39.46L310.36 256l67.37 64.43L449.38 281c19.49-10.77 19.49-39.23 0-50Z" /></svg>
                          )
                        }
                        {t("download-app.play-store")}
                      </button>
                    </div>
                    {this.state.downloadDevice === "android"
                      ? androidGuideImg &&
                      androidGuideImg.map((item, index) => {
                        if (this.state.activeSlide + 1 === index + 1) {
                          return (
                            <div key={index}>
                              <h4 className="mb-3">
                                {t("download-app.android-guide")}
                              </h4>
                              <p className="guide-step">
                                {t(`download-app.step-${index + 1}`)}
                              </p>
                              <p className="guide-desc">
                                {t(`download-app.android-step-${index + 1}`, { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                              </p>
                            </div>
                          );
                        }
                        else{
                          return null;
                        }
                      })
                      : this.state.downloadDevice === "ios" ? iosGuideImg &&
                        iosGuideImg.map((item, index) => {
                          if (this.state.activeSlide + 1 === index + 1) {
                            return (
                              <div key={index}>
                                <h4 className="mb-3">
                                  {t("download-app.ios-guide")}
                                </h4>
                                <p className="guide-step">
                                  {t(`download-app.step-${index + 1}`)}
                                </p>
                                <p className="guide-desc">
                                  {t(`download-app.ios-step-${index + 1}`, { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                                </p>
                              </div>
                            );
                          }
                          else{
                            return null;
                          }
                        })
                        : this.state.downloadDevice === "playStore" && playStoreImg &&
                        playStoreImg.map((item, index) => {
                          if (this.state.activeSlide + 1 === index + 1) {
                            return (
                              <div key={index}>
                                <h4 className="mb-3">
                                  {t("download-app.store-guide")}
                                </h4>
                                <p className="guide-step">
                                  {t(`download-app.step-${index + 1}`)}
                                </p>
                                <p className="guide-desc">
                                  {t(`download-app.store-step-${index + 1}`, { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                                </p>
                              </div>
                            );
                          }
                          else{
                            return null;
                          }
                        })

                    }
                  </div>
                </div>
              </div> */}
              
            </section>
          </>
        ) : (
          <MobileDownloadApp
            {...this.state}
            androidGuideImg={androidGuideImg}
            iosGuideImg={iosGuideImg}
          />
        )}
      </Auxiliary>
    );
  }
}

// Received the response from the redux calling the api response
const mapStateToProps = (state) => {
  return {
    rs: state.downloadApp.download,
    currentUser: state.currentUser.data,
    update_viewcount: state.referral.update_viewcount
  };
};

// Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
  return {
    getUserData,
    download: (data) => dispatch(download(data)),
    updateViewCount: (data) => dispatch(updateViewCount(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translation")(DownloadApp));
