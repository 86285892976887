import React, { Component } from "react";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
// import { isDesktop } from "react-device-detect";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Slider from "react-slick";
// import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import SideBar from "../../components/SideBar/SideBar";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import { getUserData } from "../../store/ducks/currentUser/actions";
import { connect } from "react-redux";

export class MobileDownloadApp extends Component {
  constructor(props) {
    super(props);
    // const t = this.props.t;
    this.state = {
      deviceOs: "android",
      activeSlide: 0,
    };
  }
  componentDidMount() {
    // localStorage.setItem("notFirstTime", true);
    this.mobileOsDetect();
  }

  mobileOsDetect = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      this.setState({
        deviceOs: "Windows Phone",
      });
    }
    if (/android/i.test(userAgent)) {
      this.setState({
        deviceOs: "android",
      });
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      this.setState({
        deviceOs: "ios",
      });
    }
  };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const appImg = this.imageImport(
      require.context(
        "../../assets/images/download-app",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      beforeChange: (current, next) =>
        this.setState({ oldSlide: current, activeSlide: next }),
    };
    // let merchantTitle = process.env.REACT_APP_WEBSITE_TITLE;
    return (
      <Auxiliary>
        {this.props.loading && <LoadingPage />}
        <SideBar />
        <section className="app-section">
          <div className="download-container">
            <LazyLoadImage
              className="app-img"
              src={
                this.props.currentUser.country_id !== 4 ?
                appImg["download-app-img.webp"] : appImg["download-app-img-id.webp"]
              }
              alt="download app"
            />
            <div className="download-content">
              <h1>{t("download-app.title")}</h1>
              <p>{t("download-app.title-desc")}</p>
              {this.state.deviceOs === "android" ? (
                <button
                  className="btn btn-gradient-blue mt-3"
                  target="_blank"
                  onClick={() => {
                    window.open(`https://play.google.com/store/apps/details?id=com.thinkbit.futureflow`);
                  }}
                >
                  {t("download-app.download-now")}
       
                </button>
              ) : (
                this.state.deviceOs === "ios" && (
                  <button
                    className="btn btn-gradient-blue mt-3"
                    target="_blank"
                    onClick={() => {
                      window.open(`https://apps.apple.com/us/app/chatcortex/id6738694629`);
                    }}
                  >
                    {t("download-app.download-now")}
                </button>
                )
              )}
              <Link className="btn btn-white" to={{ pathname: "/" }}>
                {t("download-app.use-mobile-webpage")}
              </Link>
            </div>
          </div>

          {/* {
            this.state.deviceOs === "ios" &&
              <div className="p-3 position-relative">
                <h1 className="text-center">{t("download-app.download-guide")}</h1>
                <div className="guide-container card-shadow">
                  <div className="guide-content w-100">
                    <div className="slick-container w-100">
                      <Slider {...settings}>
                        {
                          this.props.iosGuideImg &&
                          this.props.iosGuideImg.map((item, index) => (
                            <img
                              key={index}
                              className="guide-image"
                              src={appImg[`${item}.webp`]}
                              alt="step"
                            />
                          ))
                        }
                      </Slider>
                    </div>
                    {
                      this.props.iosGuideImg &&
                      this.props.iosGuideImg.map((item, index) => {
                        if (this.state.activeSlide + 1 === index + 1) {
                          return (
                            <div key={index}>
                              <p className="guide-step">
                                {t(`download-app.step-${index + 1}`)} :
                              </p>
                              <p className="guide-desc">
                                {t(`download-app.ios-step-${index + 1}`, { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                              </p>
                            </div>
                          );
                        }
                        else{
                          return null;
                        }
                      })
                    }
                    <p>{this.state.deviceOs}</p>
                  </div>
                </div>
              </div>
          } */}

          {/* <div className="p-3 position-relative">
            <h1 className="text-center">{t("download-app.download-guide")}</h1>
            <div className="guide-container card-shadow">
              <div className="guide-content w-100">
                <div className="slick-container w-100">
                  <Slider {...settings}>
                    {this.state.deviceOs === "android"
                      ? this.props.androidGuideImg &&
                        this.props.androidGuideImg.map((item, index) => (
                          <img
                            key={index}
                            className="guide-image"
                            src={appImg[`${item}.webp`]}
                            alt="step"
                          />
                        ))
                      : this.state.deviceOs === "ios" &&
                        this.props.iosGuideImg &&
                        this.props.iosGuideImg.map((item, index) => (
                          <img
                            key={index}
                            className="guide-image"
                            src={appImg[`${item}.webp`]}
                            alt="step"
                          />
                        ))}
                  </Slider>
                </div>
                {this.state.deviceOs === "android"
                  ? this.props.androidGuideImg &&
                    this.props.androidGuideImg.map((item, index) => {
                      if (this.state.activeSlide + 1 === index + 1) {
                        return (
                          <div key={index}>
                            <p className="guide-step">
                              {t(`download-app.step-${index + 1}`)} :
                            </p>
                            <p className="guide-desc">
                              {t(
                                `download-app.${
                                  index + 1 === 1 ? "androidMobile" : "android"
                                }-step-${index + 1}`
                              )}
                            </p>
                          </div>
                        );
                      }
                      else{
                        return null;
                      }
                    })
                  : this.state.deviceOs === "ios" &&
                    this.props.iosGuideImg &&
                    this.props.iosGuideImg.map((item, index) => {
                      if (this.state.activeSlide + 1 === index + 1) {
                        return (
                          <div key={index}>
                            <p className="guide-step">
                              {t(`download-app.step-${index + 1}`)} :
                            </p>
                            <p className="guide-desc">
                              {t(`download-app.ios-step-${index + 1}`, { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
                            </p>
                          </div>
                        );
                      }
                      else{
                        return null;
                      }
                    })}

                <p>{this.state.deviceOs}</p>
              </div>
            </div>
          </div> */}
        </section>
      </Auxiliary>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser.data,
  };
};

const mapDispatchToProps = {
  getUserData,
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translation")(MobileDownloadApp));
